@charset "UTF-8";
body {
  background-color: #fff;
  font-family: 'Fanwood Text', serif; }

.show {
  display: block !important; }

.show.hash {
  display: inline !important;
  position: absolute;
  left: -20px; }

.hide {
  display: none !important; }

a, a:hover, a:visited, a:focus {
  border: none !important;
  text-decoration: none !important; }

.wrap {
  width: 100%;
  height: 100vh; }

.gray {
  background-color: #333 !important;
  opacity: 0.7;
  border: none !important;
  color: #333 !important; }

.contentArea {
  padding: 70px 0; }
  .contentArea .main {
    margin-bottom: 30px; }
    .contentArea .main hr {
      display: none; }

.left, .right {
  padding: 80px 1%; }

.left {
  height: 500px; }
  .left .logo {
    margin-left: -20px;
    margin-bottom: 30px;
    margin-right: 40px; }
    .left .logo img {
      height: 35px; }
  .left .gnav li {
    font-size: 24px;
    margin-bottom: 10px;
    letter-spacing: 2px; }
    .left .gnav li span.active {
      position: relative; }
    .left .gnav li span {
      -webkit-text-emphasis: capitalize;
              text-emphasis: capitalize; }
  .left .pc {
    display: block !important; }

.right .cont {
  font-family: fanwood;
  margin-left: 95px;
  position: relative; }
  .right .cont .list li {
    margin-bottom: 10px; }
  .right .cont p {
    line-height: 30px;
    letter-spacing: 1.2px; }
  .right .cont table {
    margin-left: 5px; }
    .right .cont table tr {
      height: 60px; }
      .right .cont table tr td {
        letter-spacing: 1.2px; }
      .right .cont table tr td:first-child {
        width: 105px; }

.right .ttl {
  font-size: 28px;
  letter-spacing: 3px;
  margin-bottom: 30px; }

.copyright {
  font-size: 12px;
  text-align: right;
  letter-spacing: 2px; }
  .copyright br {
    display: none; }

@media only screen and (max-width: 1000px) {
  .left .gnav {
    margin-left: 5px; } }

@media only screen and (max-width: 639px) {
  .contentArea {
    position: relative;
    margin: 0;
    padding: 30px 0; }
    .contentArea .main hr {
      display: block;
      width: 70%;
      margin: 20px auto; }
  .left, .right {
    padding: 20px 0; }
  .left {
    height: auto;
    padding-top: 0; }
  .left {
    border-right: 0; }
    .left .logo {
      text-align: center;
      margin-top: 60px;
      margin-left: 0;
      margin-bottom: 20px; }
    .left .gnav {
      margin-left: 0; }
    .left .spNav {
      background-color: #333 !important;
      opacity: 0.7;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 110px auto auto;
      text-align: center;
      z-index: 99; }
      .left .spNav ul li {
        margin-bottom: 15px; }
        .left .spNav ul li a {
          cursor: pointer;
          color: #FFF; }
  .pc {
    display: none; }
  .sp {
    display: block; }
  .right {
    min-height: 250px;
    margin-bottom: 70px; }
    .right .cont {
      margin-left: 0;
      text-align: center; }
      .right .cont h2, .right .cont p {
        text-align: center; }
      .right .cont .ttl {
        font-size: 24px; }
  .sp-nav {
    z-index: 10;
    width: 100%;
    text-align: center; }
    .sp-nav .menu-trigger, .sp-nav .menu-trigger span {
      cursor: pointer;
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box; }
    .sp-nav .menu-trigger {
      position: relative;
      width: 25px;
      height: 24px; }
      .sp-nav .menu-trigger span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000; }
      .sp-nav .menu-trigger span:nth-of-type(1) {
        top: 0; }
      .sp-nav .menu-trigger span:nth-of-type(2) {
        top: 11px; }
      .sp-nav .menu-trigger span:nth-of-type(3) {
        bottom: 0; }
    .sp-nav .menu-trigger.white span {
      background-color: #fff; }
  .active .menu-trigger {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
    .active .menu-trigger span {
      background-color: #fff; }
  .gnav {
    margin-top: 30px; }
    .gnav li {
      color: #FFF; }
  .copyright {
    line-height: 26px;
    text-align: center; }
    .copyright br {
      display: block; }
  .sp-hide {
    display: none; } }

form .form-group {
  text-align: left;
  margin-bottom: 30px;
  width: 85%; }
  form .form-group label {
    font-size: 12px;
    margin-bottom: 5px; }
  form .form-group .form-control {
    border: 1px solid #aaa;
    border-radius: 0px;
    height: 32px; }
  form .form-group .invalid {
    border: 1px solid red; }
  form .form-group textarea.form-control {
    height: 62px; }
  form .form-group .formsize {
    vertical-align: top; }

form .btn {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 0px;
  color: #aaa; }

form .btn:hover {
  background: #111;
  border: 1px solid #111;
  color: #fff; }

form .submit {
  cursor: pointer; }

@media only screen and (max-width: 639px) {
  form .form-group {
    margin: 0 auto 30px; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fanwood Text', serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html, body, ul, li, p, h1, h2, h3, p, div, img {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #444; }

body {
  background-color: #fff;
  font-family: "\30D2\30E9\30AE\30CE\89D2\30B4   ProN W3", "Hiragino Kaku Gothic ProN", "\30E1\30A4\30EA\30AA", Meiryo, sans-serif; }

ul, li {
  list-style: none; }

a {
  text-decoration: none;
  font-size: inherit;
  width: 100%;
  height: 100%; }

a:hover {
  opacity: 0.7;
  text-decoration: none; }

button {
  border-width: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

table, tr, th, td {
  margin: 0;
  padding: 0;
  border-spacing: 0 !important; }

.clearfix {
  min-height: 1px; }

.inline {
  display: inline; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.center {
  text-align: center; }

.wrapper{
    display: flex;
    margin: 80px auto;
}

.container-withFooter{
  display: flex;
  flex-direction: column;
  height: 650px;
  margin: auto;
}

.container-homepage{
    display: flex;
    width: 1170px;
    margin: 0 auto;
}

.center-container {
  display: flex;
  margin: auto;
}

@media only screen and (max-width: 900px){
  .wrapper{
      display: flex;
      padding: 80px 0px;
  }

  .container-withFooter{
    width: 100%;
  }
}

@media only screen and (max-width: 639px){
    .wrapper{
        padding: 0px;
        margin: 0;
    }

    .container-homepage{
      width: 100%;
      flex-direction: column;
    }

  .container-withFooter{
    width: 100%;
    height: auto;
  }

.center-container {
  display: flex;
  flex-direction: column;
  margin: auto;
}
}
.logo{
    width: 200px;
}


@media only screen and (max-width: 639px){
    .logo{
        width: 100%;
    }
}
.container-menu{
    flex-direction: column;
    height: 430px;
    padding: 40px;
    display: flex;
}

.logo{
    margin: 25px 0px;
}

.gNav{
    padding: auto;
}

.listStyle {
    list-style: none;
}

.show.hash {
  position: relative;
  left: -10px;
}

.dragLeft {
  position: relative;
  right: 20px;
}

.hideHash {
	display: none; 
}

a {
    text-decoration: none;
    color: black;
}

li {
    font-family: 'Fanwood Text';
    font-size: 23px;
    margin-bottom: 10px;
    letter-spacing: 3px;
}

label{
    font-size: 45px;
}

.hamburger-icon{
    display: none;
    font-size: 30px;
    align-self: center;
}

#toggle{
    display: none;
}

@media only screen and (max-width: 900px){
}

@media only screen and (max-width: 639px){
  .container-menu{
      height: auto;
      padding-top: 70px;
      padding-bottom: 20px;
  }

  .hamburger-icon{
      display: inline;
  }

  .logo{
    text-align: center;
  }

  .logoImage{
    width: 250px;
  }

	#toggle:checked + .gNav {
		display: inline;
	}

}
@font-face {
    font-family: 'HiraMinPro';
    src: url(../../static/media/HiraMinPro-W3.3fabfe5f.otf);
}

.cont{
    display: flex;
    font-family: serif;
    padding: 40px;
    width: 700px;
    flex-direction: column;
    border-left: 1px solid #000;
    min-height: 280px;
}


.ttl {
    font-family: "Fanwood Text";
    font-size: 28px; 
    letter-spacing: 1.5px;
    line-height: 37px;
    text-align: left;
}

.header-spacer {    
    margin-bottom: 32px;
}

.form-group {
    display: flex;
    text-align: left;
    margin-bottom: 30px;
    width: 85%; 
    flex-direction: column;
}

label {
    font-size: 12px;
    margin-bottom: 5px;
}

.form-control {
    padding:  0px 10px;
    border: 1px solid #aaa; 
    border-radius: 0px;
    height: 32px; 
}

textarea.form-control {
    padding: 10px;
    height: 62px;
}

.formsize {
    vertical-align: top; 
}

.btn {
    background: #fff; 
    border: 1px solid #aaa; 
    border-radius: 0px;
    color: #aaa;
    padding: 6px 12px;
}

.btn:hover {
    background: #111;
    border: 1px solid #111; 
    color: #fff; 
} 

.list {
    margin-bottom: 10px; 
}

.list li{
    font-size: 15px;
}

p {
    line-height: 30px;
    letter-spacing: 1.2px;  
    line-height: 1.5; 
}

.copyright {
    text-align: right;
    letter-spacing: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.container-footer{
     margin-top: auto;
}

tr {
    height: 60px;
}

td {
    letter-spacing: 1.2px;
}

td:first-child {
    width: 105px;
}

.copyright {
    margin: 30px 30px;
}

.page-ttl {
    font-family: 'Fanwood Text';
    font-size: 28px;
    text-align: left;
    line-height: 37px;
    letter-spacing: 2.8px;
    margin-top: 20px;
    color: rgb(35,24,21);
}

.page-desc{
    font-family: 'HiraMinPro';
    font-size: 16px;
    text-align: left;
    line-height: 27px;
    letter-spacing: 0px;
    margin-top: 20px;
    color: rgb(0,0,0);
   
}
.sf-ttl{
    font-size: 28px;
    text-align: left;
    line-height: 37px;
    letter-spacing: 2.8px;
    margin-top: 40px;
    color: rgb(35,24,21);
}

.sf-desc{
    font-family: 'HiraMinPro';
    font-size: 16px;
    text-align: left;
    line-height: 27px;
    letter-spacing: 0px;
    margin-top: 20px;
    color: rgb(0,0,0);
}

.sf-img{
    width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

#sf_sp {
    display: none;
}

#sf_pc {
    display: block;
}
/****************/
/* portfolio    */
/****************/
.company-table {
   font-family: 'Fanwood Text';
}
.company-table tr{
    height: 40px;
    font-size: 16px;
}
.company-table .recruitment-hr{
    margin-top: 20px;
}
.company-table td:first-child {
   width: 120px;
}
.company-table .company-ttl{
    font-size: 20px;
    margin-bottom: 0;
}
.company-table tr:nth-child(2){
    margin-top: 16px;
    margin-bottom: 12px;
}
/****************/
/* portfolio    */
/****************/
.portfolio {
    display: flex;
    flex-wrap: wrap;
}

.portfolio .item {
    /*-webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
       -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
            box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);*/
    padding: 10px 10px 0px 0px;
}
.portfolio .item p{ font-size: 14px; }

.portfolio .portfolio-image {
    height: 180px;
    width: 290px;
    object-fit: cover;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.portfolio .portfolio-image:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}

.portfolio.single-item .portfolio-image {
    width: 100% !important;
    height: 300px;
}

.portfolio-item .portfolio-image {
    height: 200px;
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}

.line {
    border-bottom: 1px solid #444;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.pagination {
    display: flex;
    justify-content: space-around;
}

.info {
    margin-top: -2px;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 25px;
}

.desc {
    margin-bottom: 10px;
}

.img-pc{
    width: 100%;
    height: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

/****************/
/* recruitment    */
/****************/
.recruitment-ttl-underline {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Fanwood Text';
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #000000;
}
.recruitment-ttl {
    font-family: 'Fanwood Text';
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    letter-spacing: 0;
    margin-bottom: 12px;
}
.recruitment-job-container {
    margin-top: 12px;
}
.recruitment-jobs {
    font-family: 'Fanwood';
    font-size: 20px;
    font-weight: 400;
    margin-top: 8px;
}
.recruitment-link:hover {
    color: #999;
}
.recruitment-hr {
    margin-top: 8px;
    border-bottom: 1px solid #707070;
}
.hiring-process {
    display: flex;
}
.hiring-process-bullet {
    margin-right: 24px;
}
.hiring-process-ttl {
    font-family: 'Fanwood Text';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 12px;
}
.hiring-process-info{
    margin-bottom: 10px;
}
.hiring-process-vr {
    border-left: 1px solid #000000;
    height: 75%;
    margin-left: 12px;
}
.hiring-process-interview-vr {
    border-left: 1px solid #000000;
    height: 92%;
    margin-left: 12px;
}
.hiring-process-reference-vr {
    border-left: 1px solid #000000;
    height: 82%;
    margin-left: 12px;
}
.hiring-process-final-vr {
    border-left: 1px solid #000000;
    height: 66%;
    margin-left: 12px;
}
.hiring-list {
    letter-spacing: 1.2px;
    line-height: 1.5;
    font-size: 16px;
    margin: 0;
    padding: 0;
}
.hiring-list p::before {
    content: '- ';
}

/****************/
/* services    */
/****************/
.service-container {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
}
.service-item {
    margin: 0px 60px 40px 0px;
    text-align: center;
}
.service-image {
    width: auto;
    height: auto;
}

.service-item-ttl{
    font-family: 'Fanwood Text';
    font-size: 20px;
    letter-spacing: 0;
    margin-bottom: 12px;
}

.service-item-link{
    font-size: 16px;    
}

/*******************/
/* service development  */
/*******************/
.service-dev-container{
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.sd-item {
    width: 160px;
    height: auto;
    text-align: left;
    margin-right: 20px;
}

.sd-image {
    height: 122px;
    width: 160px;
    margin-right: 20px;
}

.sd-line {
    border-bottom: 1px solid #444;
    margin-bottom: 8px;
}
.menu-line-top {
    border-bottom: 1px solid rgb(112,112,112);
    margin-bottom: 0px;
    margin-top: 20px;
}

.menu-line-bottom {
    border-bottom: 1px solid rgb(112,112,112);
    margin-top: 0px;
}

.sd-item-ttl{
    font-family: 'HiraMinPro';
    letter-spacing: 0px;
    font-size: 14px;
    line-height: 24px;
    margin-top: 2px;
}
.sd-item-desc{
    font-family: 'HiraMinPro';
    font-size: 12px;
    letter-spacing: 0;
    text-align: left;
    line-height: 20px;
    color: rgb(0,0,0);
}
.sd-item-img{
    height: 122px;
    width: 160px;
}

.sd-desc{
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0px;
    margin-top: 20px;
    text-align: left;
    font-family: 'HiraMinPro';
}
/*******************/
/* RPA Consulting       */
/*******************/
.rpa-img{
    width: 100%;
    height: auto;
}

.rpa-ttl {
    font-family: 'HiraMinPro';
    margin-top: 32px;
    width: 50%;
    float: left;
    margin-bottom: 33px;
}

.rpa-ttl-1{
    font-family: 'HiraMinPro';
    margin-top: 32px;
    width: 50%;
    float: left;
    margin-bottom: 33px;
    text-align: center;
}

.rpa-sf-ttl{
    font-family: 'Fanwood Text';
    font-size: 28px;
    text-align: left;
    line-height: 37px;
    letter-spacing: 2.8px;
    margin-top: 40px;
    color: rgb(35,24,21);
}

.rpa-sf-img{
    width: 100%;
    height: auto;
    margin-top: 27px;
    margin-bottom: 40px;
}


/*******************/
/* AI development  */
/*******************/
.ai-ttl {
    font-family: 'Fanwood Text';
    font-size: 28px; 
    letter-spacing: 2.8px;
    line-height: 37px;
    margin-top: 20px;
    text-align: left;
}

.ai-desc{
    margin-top: 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 20px;
    text-align: left;
}


.ai-sub-desc{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
    font-family: 'HiraMinPro';
}
.ai-kind-container{
    margin-top: 0px;
    margin-bottom: 15px;
}
.ai-kind-img{
    float: left;
    height: 75px;
    width: 75px ;
    margin-left: -8px;
    margin-right: 14px;
}

.ai-kind-ttl {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 34px;
    text-align: left;
    color: rgb(0,0,0);
}

.ai-kind-desc{
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    color: rgb(0,0,0);
}

.ai-img{
    width: 100%;
    height: auto;
}

.ai-sf-ttl{
    font-family: 'Fanwood Text';
    font-size: 28px;
    text-align: left;
    line-height: 37px;
    letter-spacing: 2.8px;
    margin-top: 40px;
    color: rgb(35,24,21);
}

/*******************/
/* E commerce  */
/*******************/
.e-dev-ttl {
    font-family: 'Fanwood Text';
    font-size: 24px;
    text-align: left;
    line-height: 31px;
    letter-spacing: 2.4px;
    margin-top: 65px;
    color: rgb(35,24,21);
}

.e-dev-desc {
    font-family: 'HiraMinPro';
    font-size: 16px;
    text-align: left;
    line-height: 27px;
    letter-spacing: 0px;
    margin-top: 16px;
    color: rgb(0,0,0);
}

.dev-container{
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
}

.e-address-container{
    margin-top: 12px;
    display: grid;
    grid-template-columns: auto auto auto;
}

.e-consulting-container{
    margin-top: 14px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
}

.dev-sub-container{
    margin-right: 32px;
}

.e-img{
    width: 200px;
    height: auto;
}

.e-desc{
    font-family: 'HiraMinPro';
    font-size: 12px;
    line-height: 20px;
    margin-top: 20px;
    letter-spacing: 0;
    text-align: left;
    color: rgb(0,0,0);
}

.e-address-desc{
    font-family: 'HiraMinPro';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: left;
    margin-left: 42px;
    color: rgb(0,0,0);
}

.e-p{
    font-family: 'HiraMinPro';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: left;
    color: rgb(0,0,0);
    margin-top: 65px;
}

.e-consulting-p{
    font-family: 'HiraMinPro';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: left;
    color: rgb(0,0,0);
    margin-top: 45px;
}

/*******************/
/* Branding  */
/*******************/
.branding-sf-img{
    width: 100%;
    height: auto;
    margin-top: 60px;
    margin-bottom: 90px;
}

.branding-sf-ttl{
    font-family: 'Fanwood Text';
    font-size: 28px;
    text-align: left;
    line-height: 37px;
    letter-spacing: 2.8px;
    margin-top: 55px;
    color: rgb(35,24,21);
}



@media only screen and (max-width: 900px){
    .cont {
        padding: 70px;
        width: 300px;
    }
    /*******************/
    /* portfolio       */
    /*******************/
    .portfolio, .info {
        flex-direction: column;
    }
    
    .portfolio .portfolio-image {
        width: 100%;
        height: 200px;
    }

    .portfolio.single-item .portfolio-image,
    .portfolio-item .portfolio-image {
        height: 200px;
    }
}

@media only screen and (max-width: 639px){
    .cont {
        font-family: 'Fanwood';
        padding: 0px;
        width: 350px;
        margin: auto;
        text-align: center;
        border-left: none;
        min-height: 20px;
    }

    .container-footer {
        margin: 50px;
    }

	.copyright {
		line-height: 26px;
        text-align: center;
        font-size: 9px;
        margin: none;
    }

    .page-ttl {
        font-family: 'Fanwood Text';
        font-size: 24px;
        text-align: left;
        line-height: 31px;
        letter-spacing: 2.4px;
        margin-top: 20px;
        color: rgb(35,24,21);
    }
    
    .page-desc{
        font-family: 'HiraMinPro';
        font-size: 14px;
        text-align: left;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 20px;
        color: rgb(0,0,0);
    }
    .sf-ttl{
        font-size: 24px;
        text-align: left;
        line-height: 31px;
        letter-spacing: 2.4px;
        margin-top: 38px;
        color: rgb(35,24,21);
    }
    
    .sf-desc{
        font-size: 14px;
        text-align: left;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 20px;
        color: rgb(0,0,0);
        font-family: 'HiraMinPro';
    }

    .sf-img{
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    #sf_pc {
        display: none;
    }
    #sf_sp {
        display: block;
    }

    .responsive_sm {
        text-align: left;
    }

    .responsive_table tr {
        display: block;
        margin-bottom: 8px;
    }
    .responsive_table tr:last-child {
        margin-top: 88px;
    }
    .responsive_table td {
        display: block;
        width: 100%;
    }

    .hiring-process-vr {
        height: 88%;
    }
    .hiring-process-interview-vr {
        height: 96%;
    }
    /*******************/
    /* company       */
    /*******************/
    .company-table tr {
        display: table;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .company-table .company-ttl {
        margin-top: 0;
    }
    .company-table tr:nth-child(2){
        margin-top: 16px;
        margin-bottom: 0;
    }
    .company-table tr:last-child{
        margin-top: 16px;
        margin-bottom: 24px;
    }
    .company-table td:first-child{
        margin-bottom: 8px;
    }
    .company .header-spacer{
        margin-bottom: 24px;
    }


    /*******************/
    /* portfolio       */
    /*******************/
    .portfolio .item {
        padding: 10px;
    }

    /****************/
    /* services    */
    /****************/
    .service-container {
        margin-top: 0px;
        display: grid;
        grid-template-columns: auto auto;
    }

    .service-item {
        margin: 24px 36px 0px 0px;
        text-align: center;
    }
    
    .service-dev-container {
        margin-top: 20px;
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: auto auto;
    }

    /*******************/
    /* RPA Consulting       */
    /*******************/   
    
    .rpa-ttl {
        font-family: 'Fanwood Text';
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 33px;
        text-align: left;
        line-height: 34px;
        color: rgb(0,0,0);
    }

    .rpa-ttl-1 {
        font-family: 'Fanwood Text';
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 14px;
        text-align: left;
        line-height: 34px;
        color: rgb(0,0,0);
        width: 100%;
    }

    .rpa-img{
        width: 100%;
        height: auto;
    }

    .rpa-sf-ttl{
        font-family: 'Fanwood Text';
        margin-top: 38px;
        font-size: 24px;
        text-align: left;
        line-height: 31px;
        letter-spacing: 2.4px;
        color: rgb(35,24,21);
    }
    
    .rpa-sf-img{
        width: 100%;
        height: auto;
        margin-top: 48px;
        margin-bottom: 40px;
    }
    

    /*******************/
    /* E commerce       */
    /*******************/   

    .dev-container{
        margin-top: 5px;
        display: grid;
        grid-template-columns: auto;
        text-align: left;
    }

    .e-address-desc{
        font-family: 'HiraMinPro';
        font-size: 14px;
        letter-spacing: 0;
        color: rgb(0,0,0);
        line-height: 24px;
        margin-top: 12px;
        text-align: left;
        margin-left: 0px;
    }

    .e-desc{
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        letter-spacing: 0;
        text-align: left;
        color: rgb(0,0,0);
    }

    .dev-sub-container{
        margin-top: 12px;
        margin-right: 0px;
    }

    .e-consulting-sub-container{
        margin-top: 30px;
        margin-right: 0px;
    }

    .e-address-container{
        text-align: left;
        margin-top: 12px;
        display: grid;
        grid-template-columns: auto;
    }

    .e-dev-ttl {
        font-family: 'Fanwood Text';
        font-size: 24px;
        text-align: left;
        line-height: 31px;
        letter-spacing: 2.4px;
        margin-top: 23.5px;
        color: rgb(35,24,21);
    }

    .e-dev-desc {
        font-size: 14px;
        text-align: left;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 20px;
        color: rgb(0,0,0);
    }

    .e-consulting-container{
        margin-top: 14px;
        margin-bottom: 40px;
        display: grid;
        grid-template-columns: auto;
    }

    .menu-line-top {
        border-bottom: 1px solid rgb(112,112,112);
        margin-bottom: 0px;
        margin-top: 24px;
    }

    .e-p { 
        font-size: 16px;
        letter-spacing: 0;
        line-height: 27px;
        text-align: left;
        margin-top: 26px;
        color: rgb(0,0,0);
    }


    .ai-ttl {
        font-family: 'Fanwood Text';
        font-size: 24px; 
        letter-spacing: 2.4px;
        line-height: 31px;
        margin-top: 24px;
        text-align: left;
    }

    .ai-desc{
        margin-top: 20px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: left;
    }

    .ai-sub-desc{
        margin-top: 20px;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 20px;
        text-align: left;
        font-family: 'HiraMinPro';
    }

    .ai-kind-ttl {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 27px;
        text-align: left;
        margin-top: 10px;
        font-family: 'HiraMinPro';
    }
    
    .ai-kind-desc{
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: 10px;
        text-align: left;
        font-family: 'HiraMinPro';
    }

    .ai-kind-img{
        float: left;
        height: 75px;
        width: 75px ;
        margin-left: -12px;
        margin-right: 14px;
    }

    .ai-sf-ttl{
        font-family: 'Fanwood Text';
        font-size: 24px;
        text-align: left;
        line-height: 37px;
        letter-spacing: 2.8px;
        margin-top: 60px;
        color: rgb(35,24,21);
    }

    .sd-item {
        width: 160px;
        height: auto;
        text-align: left;
        margin-right: 29px;
    }
    
    .sd-item-ttl{
        letter-spacing: 0px;
        font-size: 14px;
        line-height: 24px;
        margin-top: 2px;
    }
    .sd-item-desc{
        font-size: 12px;
        letter-spacing: 0;
        text-align: left;
        line-height: 20px;
        margin-bottom: 20px;
    }
    .sd-item-img{
        height: 122px;
        width: 160px;
    }

    .sd-line {
        border-bottom: 1px solid rgb(209,209,209);
        margin-bottom: 8px;
    }
    
    .branding-sf-ttl{
        font-family: 'Fanwood Text';
        font-size: 24px;
        text-align: left;
        line-height: 37px;
        letter-spacing: 2.8px;
        margin-top: 0px;
        color: rgb(35,24,21);
    }

    .branding-sf-img{
        width: 100%;
        height: auto;
        margin-top: 19.5px;
        margin-bottom: 33.5px;
    }

    .e-img{
        width: 351px;
        height: auto;
    }
}
.sd-menu {
    margin-top: 20px;
    height: 24px;
    width: auto;
}

.sd-menu li {
    display: inline-block;
    border-right: 1px solid #000;
    line-height: 5px;   
}

.sd-menu  li a {
    text-align: left;
    font-size: 18px;
    margin:0px 10px 0px 10px;
    letter-spacing: 0;
    line-height: 23px;
    color: #7C7C7C;
}

.sd-menu li a.active{
    color: rgb(35,24,21);
}

.sd-menu li:last-child {
    border-right: none;
}
.sd-menu li:first-child{
    margin:0px 0px 0px -10px;
}


#sf_sp {
    display: none;
}
#sf_pc {
    display: block;
}

@media only screen and (max-width: 639px){
    #sf_pc {
        display: none;
    }
    #sf_sp {
        display: block;
    }
    .sd-menu {
        margin-top: 40px;
        height: auto;
        width: auto;
    }

    .sd-menu  li a {
        text-align: left;
        font-size: 18px;
        letter-spacing: 0;
        color: #7C7C7C;
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 0px;
        margin-right: 0px;
        height: 23px;
        display: inline-block;
    }
    
    .sd-menu li a.active{
        color: rgb(35,24,21);
    }
    
    .sd-menu ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
      
    .sd-menu  li {
        text-align: left;
        width: 100%;
        border-bottom: 1px solid #CCCCCC;
        border-right: none;
    }
      
    .sd-menu li:first-child{
        margin:0px 0px 0px 0px;
        border-top: 1px solid #CCCCCC;
    }
    
}
	
.switch-wrapper > div {
	position: absolute;
}


@media only screen and (max-width: 639px){	
  .switch-wrapper > div {
	position: relative;
  }
}
