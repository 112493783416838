	
.switch-wrapper > div {
	position: absolute;
}


@media only screen and (max-width: 639px){	
  .switch-wrapper > div {
	position: relative;
  }
}