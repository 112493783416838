.sd-menu {
    margin-top: 20px;
    height: 24px;
    width: auto;
}

.sd-menu li {
    display: inline-block;
    border-right: 1px solid #000;
    line-height: 5px;   
}

.sd-menu  li a {
    text-align: left;
    font-size: 18px;
    margin:0px 10px 0px 10px;
    letter-spacing: 0;
    line-height: 23px;
    color: #7C7C7C;
}

.sd-menu li a.active{
    color: rgb(35,24,21);
}

.sd-menu li:last-child {
    border-right: none;
}
.sd-menu li:first-child{
    margin:0px 0px 0px -10px;
}


#sf_sp {
    display: none;
}
#sf_pc {
    display: block;
}

@media only screen and (max-width: 639px){
    #sf_pc {
        display: none;
    }
    #sf_sp {
        display: block;
    }
    .sd-menu {
        margin-top: 40px;
        height: auto;
        width: auto;
    }

    .sd-menu  li a {
        text-align: left;
        font-size: 18px;
        letter-spacing: 0;
        color: #7C7C7C;
        margin-top: 12px;
        margin-bottom: 12px;
        margin-left: 0px;
        margin-right: 0px;
        height: 23px;
        display: inline-block;
    }
    
    .sd-menu li a.active{
        color: rgb(35,24,21);
    }
    
    .sd-menu ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
      
    .sd-menu  li {
        text-align: left;
        width: 100%;
        border-bottom: 1px solid #CCCCCC;
        border-right: none;
    }
      
    .sd-menu li:first-child{
        margin:0px 0px 0px 0px;
        border-top: 1px solid #CCCCCC;
    }
    
}