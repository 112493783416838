@charset "UTF-8";
body {
  background-color: #fff;
  font-family: 'Fanwood Text', serif; }

.show {
  display: block !important; }

.show.hash {
  display: inline !important;
  position: absolute;
  left: -20px; }

.hide {
  display: none !important; }

a, a:hover, a:visited, a:focus {
  border: none !important;
  text-decoration: none !important; }

.wrap {
  width: 100%;
  height: 100vh; }

.gray {
  background-color: #333 !important;
  opacity: 0.7;
  border: none !important;
  color: #333 !important; }

.contentArea {
  padding: 70px 0; }
  .contentArea .main {
    margin-bottom: 30px; }
    .contentArea .main hr {
      display: none; }

.left, .right {
  padding: 80px 1%; }

.left {
  height: 500px; }
  .left .logo {
    margin-left: -20px;
    margin-bottom: 30px;
    margin-right: 40px; }
    .left .logo img {
      height: 35px; }
  .left .gnav li {
    font-size: 24px;
    margin-bottom: 10px;
    letter-spacing: 2px; }
    .left .gnav li span.active {
      position: relative; }
    .left .gnav li span {
      text-emphasis: capitalize; }
  .left .pc {
    display: block !important; }

.right .cont {
  font-family: fanwood;
  margin-left: 95px;
  position: relative; }
  .right .cont .list li {
    margin-bottom: 10px; }
  .right .cont p {
    line-height: 30px;
    letter-spacing: 1.2px; }
  .right .cont table {
    margin-left: 5px; }
    .right .cont table tr {
      height: 60px; }
      .right .cont table tr td {
        letter-spacing: 1.2px; }
      .right .cont table tr td:first-child {
        width: 105px; }

.right .ttl {
  font-size: 28px;
  letter-spacing: 3px;
  margin-bottom: 30px; }

.copyright {
  font-size: 12px;
  text-align: right;
  letter-spacing: 2px; }
  .copyright br {
    display: none; }

@media only screen and (max-width: 1000px) {
  .left .gnav {
    margin-left: 5px; } }

@media only screen and (max-width: 639px) {
  .contentArea {
    position: relative;
    margin: 0;
    padding: 30px 0; }
    .contentArea .main hr {
      display: block;
      width: 70%;
      margin: 20px auto; }
  .left, .right {
    padding: 20px 0; }
  .left {
    height: auto;
    padding-top: 0; }
  .left {
    border-right: 0; }
    .left .logo {
      text-align: center;
      margin-top: 60px;
      margin-left: 0;
      margin-bottom: 20px; }
    .left .gnav {
      margin-left: 0; }
    .left .spNav {
      background-color: #333 !important;
      opacity: 0.7;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 110px auto auto;
      text-align: center;
      z-index: 99; }
      .left .spNav ul li {
        margin-bottom: 15px; }
        .left .spNav ul li a {
          cursor: pointer;
          color: #FFF; }
  .pc {
    display: none; }
  .sp {
    display: block; }
  .right {
    min-height: 250px;
    margin-bottom: 70px; }
    .right .cont {
      margin-left: 0;
      text-align: center; }
      .right .cont h2, .right .cont p {
        text-align: center; }
      .right .cont .ttl {
        font-size: 24px; }
  .sp-nav {
    z-index: 10;
    width: 100%;
    text-align: center; }
    .sp-nav .menu-trigger, .sp-nav .menu-trigger span {
      cursor: pointer;
      display: inline-block;
      transition: all .4s;
      box-sizing: border-box; }
    .sp-nav .menu-trigger {
      position: relative;
      width: 25px;
      height: 24px; }
      .sp-nav .menu-trigger span {
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #000; }
      .sp-nav .menu-trigger span:nth-of-type(1) {
        top: 0; }
      .sp-nav .menu-trigger span:nth-of-type(2) {
        top: 11px; }
      .sp-nav .menu-trigger span:nth-of-type(3) {
        bottom: 0; }
    .sp-nav .menu-trigger.white span {
      background-color: #fff; }
  .active .menu-trigger {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
    .active .menu-trigger span {
      background-color: #fff; }
  .gnav {
    margin-top: 30px; }
    .gnav li {
      color: #FFF; }
  .copyright {
    line-height: 26px;
    text-align: center; }
    .copyright br {
      display: block; }
  .sp-hide {
    display: none; } }

form .form-group {
  text-align: left;
  margin-bottom: 30px;
  width: 85%; }
  form .form-group label {
    font-size: 12px;
    margin-bottom: 5px; }
  form .form-group .form-control {
    border: 1px solid #aaa;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    height: 32px; }
  form .form-group .invalid {
    border: 1px solid red; }
  form .form-group textarea.form-control {
    height: 62px; }
  form .form-group .formsize {
    vertical-align: top; }

form .btn {
  background: #fff;
  border: 1px solid #aaa;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  color: #aaa; }

form .btn:hover {
  background: #111;
  border: 1px solid #111;
  color: #fff; }

form .submit {
  cursor: pointer; }

@media only screen and (max-width: 639px) {
  form .form-group {
    margin: 0 auto 30px; } }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Fanwood Text', serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html, body, ul, li, p, h1, h2, h3, p, div, img {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: #444; }

body {
  background-color: #fff;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif; }

ul, li {
  list-style: none; }

a {
  text-decoration: none;
  font-size: inherit;
  width: 100%;
  height: 100%; }

a:hover {
  opacity: 0.7;
  text-decoration: none; }

button {
  border-width: 0; }

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden; }

table, tr, th, td {
  margin: 0;
  padding: 0;
  border-spacing: 0 !important; }

.clearfix {
  min-height: 1px; }

.inline {
  display: inline; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.center {
  text-align: center; }
