.container-menu{
    flex-direction: column;
    height: 430px;
    padding: 40px;
    display: flex;
}

.logo{
    margin: 25px 0px;
}

.gNav{
    padding: auto;
}

.listStyle {
    list-style: none;
}

.show.hash {
  position: relative;
  left: -10px;
}

.dragLeft {
  position: relative;
  right: 20px;
}

.hideHash {
	display: none; 
}

a {
    text-decoration: none;
    color: black;
}

li {
    font-family: 'Fanwood Text';
    font-size: 23px;
    margin-bottom: 10px;
    letter-spacing: 3px;
}

label{
    font-size: 45px;
}

.hamburger-icon{
    display: none;
    font-size: 30px;
    align-self: center;
}

#toggle{
    display: none;
}

@media only screen and (max-width: 900px){
}

@media only screen and (max-width: 639px){
  .container-menu{
      height: auto;
      padding-top: 70px;
      padding-bottom: 20px;
  }

  .hamburger-icon{
      display: inline;
  }

  .logo{
    text-align: center;
  }

  .logoImage{
    width: 250px;
  }

	#toggle:checked + .gNav {
		display: inline;
	}

}