body{
	background-color: #fff; 
	font-family: 'Fanwood Text', serif;
}

.show {
	display: block !important; 
}

.show.hash {
	display: inline !important;
	position: absolute;
    left: -20px;
}

.hide {
	display: none !important; 
}

a, a:hover, a:visited, a:focus {
	border: none !important;
	text-decoration: none !important;
}

.wrap {
	width: 100%;
	height: 100vh;
}

.gray {
	background-color: #333 !important;
	opacity: 0.7;  
	border: none!important;
	color: #333 !important; 
}

.contentArea {
	padding: 70px 0;
	
	.main {
		margin-bottom: 30px;
		
		hr {
			display: none; 
		}
	}
}

.left, .right {
	padding: 80px 1%;
}

.left {
	height: 500px;
	
	.logo {
    margin-left: -20px;
    margin-bottom: 30px;
    margin-right: 40px;
		
		img {
			height: 35px;
		}
	}
	
	.gnav {
		
		li {
			font-size: 24px;
			margin-bottom: 10px;
			letter-spacing: 2px; 
			
			span.active {
				position: relative;
			}
			
			span {
				text-emphasis: capitalize;
			}
		}

	}

	.pc {
		display: block !important; 
	}
}

.right {
	
	.cont {
		font-family: fanwood;
		margin-left: 95px;
    	position: relative;
		
		.list {
			li {
				margin-bottom: 10px; 
			}
		}

		p {
			line-height: 30px;
			letter-spacing: 1.2px;   
		}

		table {
			margin-left: 5px;
			 
			tr {
				height: 60px; 
				
				td {
					letter-spacing: 1.2px;
				}
				
				td:first-child {
					width: 105px;
				}
			}
		}
	}

	.ttl {
		font-size: 28px; 
		letter-spacing: 3px;
		margin-bottom: 30px;
	}

}

.copyright {
	font-size: 12px;
	text-align: right;
	letter-spacing: 2px;
	
	br {
		display: none; 
	}
}



@media only screen and (max-width: 1000px){
	.left {
		.gnav {
			margin-left: 5px;
		}
	}
}

// sp
@media only screen and (max-width: 639px){
	.contentArea {
		position: relative;
		margin: 0;
		padding: 30px 0;
		
		.main {
			hr {
				display: block; 
				width: 70%;
				margin: 20px auto;
			}
		}
	}

	.left, .right {
		padding: 20px 0; 
	}
	.left {height: auto; padding-top: 0;}

	.left {
		border-right: 0;
		
		.logo {
			text-align: center;
			margin-top: 60px;
			margin-left: 0; 
			margin-bottom: 20px;
		}

		.gnav {
			margin-left: 0;
		}

		.spNav {
			background-color: #333 !important;
			opacity: 0.7;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			margin: 110px auto auto;
			text-align: center;
			z-index: 99;
			
			ul {
				li {
					margin-bottom: 15px;
					a {
						cursor: pointer;
						color: #FFF; 
					}
				}
			}
		}
	}
	.pc {
		display: none;
	}
	.sp {
		display: block;
	}

	.right {
		min-height: 250px;
		margin-bottom: 70px;
		 
		.cont {
			margin-left: 0;
			text-align: center;
			
			h2, p {
				text-align: center;
			}

			.ttl {
				font-size: 24px; 
			}
		}
	}
	
	.sp-nav{
		z-index: 10;
		width: 100%;
		text-align: center;
		
		.menu-trigger,.menu-trigger span {
			cursor: pointer;
			display: inline-block;
			transition: all .4s;
			box-sizing: border-box;
		}
		.menu-trigger {
				position: relative;
				width: 25px;
				height: 24px;
			span {
				position: absolute;
				left: 0;
				width: 100%;
				height: 3px;
				background-color: #000;
			}
			span:nth-of-type(1) {
				top: 0;
			}
			span:nth-of-type(2) {
				top: 11px;
			}
			span:nth-of-type(3) {
				bottom: 0;
			}
		}
		.menu-trigger.white span {
			background-color: #fff; 
		}
	}

	.active {
		.menu-trigger {
			-webkit-transform: rotate(90deg);
			transform: rotate(90deg);
		    span {
				background-color: #fff;
			}
		}
	}

	.gnav {
		margin-top: 30px;
		li {
			color: #FFF;
		} 
	}
	.copyright {
		line-height: 26px;
		text-align: center;
		
		br {
			display: block; 
		}
	}

	.sp-hide {
		display: none; 
	}
}


form {
	.form-group {
		text-align: left;
		margin-bottom: 30px;
		width: 85%; 

		label {
			font-size: 12px;
			margin-bottom: 5px;
		}
		.form-control {
			border: 1px solid #aaa; 
			-webkit-border-radius: 0px; 
			border-radius: 0px;
      height: 32px;
    }
    .invalid{
      border: 1px solid red; 
    }
		textarea.form-control {
			height: 62px;
		}
		.formsize {
			vertical-align: top; 
		}
	}

	.btn {
		background: #fff; 
		border: 1px solid #aaa;
		-webkit-border-radius: 0px; 
		 border-radius: 0px;
		color: #aaa;
	}
	.btn:hover {
		background: #111;
		border: 1px solid #111; 
		color: #fff; 
  }
  .submit {
  	cursor: pointer;
  }
  
}

@media only screen and (max-width: 639px){
	form {
		.form-group {
			margin: 0 auto 30px;
		}
	}
}
